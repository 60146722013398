:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}
@supports ((height: constant(safe-area-inset-top)) or (height: env(safe-area-inset-top))) and
  (-webkit-overflow-scrolling: touch) {
  .fullscreen {
    /* 适配齐刘海 */
    padding-top: 20px;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

    /* 适配底部小黑条 */
    padding-bottom: 0;
    padding-bottom: costant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  /* 适配ios 非齐刘海机型 */
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  /* 针对移动端 */
  box-sizing: border-box;
  /* 主流浏览器兼容处理，移动端的浏览器通常的话，都是基于webkit效果的 */
  -webkit-box-sizing: border-box;
  /* 清除移动端浏览器的高亮效果 */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 为导航栏+状态栏的高度 88px */
  padding-top: constant(safe-area-inset-top);
  /* 如果未竖屏时为0 */
  padding-left: constant(safe-area-inset-left);
  /* 如果未竖屏时为0 */
  padding-right: constant(safe-area-inset-right);
  /* 为底下圆弧的高度 34px */
  padding-bottom: constant(safe-area-inset-bottom);
}

html,
body,
#root-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root-container {
  position: relative;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow-y: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

body::before {
  width: 100%;
  height: 100%;
  content: ' ';
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: #fff;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

input,
textarea {
  outline: none;
  border: none;
  /* 禁止textarea的右下角的放大效果 */
  resize: none;
  /* 清除移动端输入框特有的样式 */
  -webkit-appearance: none;
  -webkit-user-select: auto;
}

input::-webkit-input-placeholder {
  font-weight: 400;
  color: #c9c9c9;
  line-height: 40px;
  font-size: 30px;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  width: 0px;
}

.geetest_copyright {
  display: none;
}

.geetest_feedback {
  display: none !important;
}

.geetest_panel_ghost {
  background-color: rgba(256, 256, 256, 0) !important;
}

.geetest_wind.geetest_panel .geetest_panel_box {
  border-radius: 12px !important;
  border: none !important;
  width: 538px;
  box-shadow: 0px 6px 30px 0px rgba(125, 127, 130, 0.3) !important;
}
.adm-modal {
  .adm-mask {
    background: rgba(0, 0, 0, 0.5) !important;
  }
  .adm-center-popup-wrap {
    transform: translate3d(-50%, -50%, 100px);
    z-index: 999;
    width: 280px;
    background: #ffffff;
    border-radius: 12px;
    .adm-modal-body {
      padding-top: 0.28rem;
      &.hide-modal-content {
        .adm-modal-title {
          margin-bottom: 0.26rem;
        }
        .adm-modal-content {
          display: none;
        }
      }
      .adm-modal-title {
        margin-bottom: 0.12rem;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #35394d;
        padding: 0px 20px;
      }
      .adm-modal-content {
        padding: 0rem 0.2rem 0rem;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 0.14rem;
        line-height: 0.2rem;
        text-align: center;
        color: #666a80;
      }
      .adm-modal-footer {
        padding: 0 0.2rem 0.2rem;
        margin-top: 0.28rem;
        display: flex;
        flex-direction: row;
        .adm-space-item {
          flex: 1;
          --gap-vertical: 0;
          &:nth-child(2) {
            margin-left: 8px;
          }
          .adm-button {
            width: 100%;
            height: 40px;
            background: #5991ff;
            color: #ffffff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
          .adm-modal-button-primary {
            border-color: #f2f3f7;
            background: #f2f3f7;
            color: #35394d;
          }
        }
      }
    }
  }
}
.alert-modal {
  .adm-modal-button-primary {
    background: #5991ff !important;
    color: #ffffff !important;
  }
}
.adm-mask {
  .adm-mask-content {
    .adm-toast-wrap {
      .adm-toast-main {
        padding: 0;
        background: rgba(53, 57, 77, 0.9);
        border-radius: 12px;
        min-width: 121px;
        min-height: 84px;
        padding: 16px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 210px;
        .adm-toast-icon {
          margin-bottom: 8px;
          height: 24px;
          img {
            height: 24px;
            width: 24px;
          }
        }
        .adm-auto-center-content {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
.adm-center-popup-wrap {
}
.not-allowed-content {
  margin-top: 12px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #aaabb3;
}
.not-allowed-title {
  .icon {
    margin-bottom: 6px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.toast-not-allowed-title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
}
.toast-not-allowed-content {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
}

.login-law {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &-off {
    margin-right: 4px;
    width: 16px;
    height: 16px;
    background: url(../images/login/off.svg) no-repeat no-repeat center center / 100% 100%;
  }

  &-on {
    margin-right: 4px;
    width: 16px;
    height: 16px;
    background: url(../images/login/on.svg) no-repeat no-repeat center center / 100% 100%;
  }

  p {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #aaabb3;
  }
  &-term {
    color: #5991ff;
  }
}
